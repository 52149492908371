import { AppPlugin, AppRootProps } from '@grafana/data';
import { EntityAssertionsWidgetProps } from 'externalComponents/EntityAssertionsWidget/EntityAssertionsWidget';
import { configureGrafanaExtensions } from 'app/grafanaExtensions';
import { AssertsAppJsonData } from 'asserts-types';
import React, { lazy, Suspense } from 'react';
import pluginJson from 'plugin.json';
import FallbackLoadingPlaceholder from 'components/FallbackLoadingPlaceholder';

const LazyApp = lazy(() => import('./features/App'));
const ExternalComponentWrapper = lazy(() => import('externalComponents/ExternalComponentWrapper'));
const EntityAssertionsWidget = lazy(() => import('externalComponents/EntityAssertionsWidget/EntityAssertionsWidget'));

const App = (props: AppRootProps<AssertsAppJsonData>) => (
  <Suspense fallback={<FallbackLoadingPlaceholder />}>
    <LazyApp {...props} />
  </Suspense>
);

const plugin = new AppPlugin<AssertsAppJsonData>().setRootPage(App);

if (plugin.exposeComponent) {
  plugin.exposeComponent({
    id: `${pluginJson.id}/entity-assertions-widget/v1`,
    title: 'Entity Assertions Widget',
    description: 'Widget for displaying entity assertions',
    component: (props: EntityAssertionsWidgetProps) => {
      return (
        <Suspense fallback={<FallbackLoadingPlaceholder />}>
          <ExternalComponentWrapper>
            <EntityAssertionsWidget {...props} />
          </ExternalComponentWrapper>
        </Suspense>
      );
    },
  });
}

configureGrafanaExtensions(plugin);

export { plugin };
