import { AppPlugin } from '@grafana/data';
import { AssertsAppJsonData } from 'asserts-types';
import React, { lazy, Suspense } from 'react';
const AlertingHomepageCallout = lazy(() => import('components/AlertingHomepageCallout'));

export function configureGrafanaExtensions(plugin: AppPlugin<AssertsAppJsonData>) {
  plugin.configureExtensionComponent({
    extensionPointId: 'grafana/alerting/home',
    title: 'Asserts',
    description: 'Grafana Asserts App',
    component: () => {
      return (
        <Suspense fallback={null}>
          <AlertingHomepageCallout />
        </Suspense>
      );
    },
  });
}
